$black: #000;
$white: #fff;
$fontmedium: "Roboto", Myanmar3 !important;

.confirmation-upper {
  text-align: center;
  width: 100%;
  img {
    margin: 50px 0 20px 0;
  }
  .title {
    color: $black;
    font-family: $fontmedium;
    font-weight: 500;
    font-size: 22px;
    display: block;
    margin-bottom: 15px;
  }
  .bodytext {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 18px;
    color: #4e4e4e;
  }
}

.middleregion {
  //padding-right: 5px;
  // border-right: 1px dashed #707070;
  // float: left;
  // width: 75%;
  box-sizing: border-box;
  @media (max-width: 760px) {
    width: 100%;
    float: none;
    border-right: 0px;
    padding-right: 0px;
    border-bottom: 1px dashed #707070;
  }
}

.thirdregion {
  padding: 0 0 0 30px;
  float: left;
  width: 25%;
  box-sizing: border-box;
  @media (max-width: 760px) {
    width: 100%;
    float: none;
    padding-left: 0px;
  }
}

.confirmation-lower {
  width: 75% !important;
  margin: 0 auto;
  border-radius: 5px;
  background-color: $white;
  margin-top: 30px;
  padding: 20px 10px 10px 10px;
  overflow: hidden;
  img {
    width: 100px;
  }
  .total {
    margin-left: -10px;
    width: 75%;
    margin-top: 20px;
    background: linear-gradient(270deg, #0e99eb, #46c4f4 100%);
    padding: 5px 10px 5px 10px;
    border-radius: 0px 5px 5px 0px;
    text-align: right;

    .total-text {
      font-size: 11px;
      color: $white;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
    }
    .total-amt {
      font-size: 23px;
      color: $white;
      font-family: "Roboto" !important;
      font-weight: 400;
    }
  }
  #detaildiv-datetime {
    display: flex;
    @media (max-width: 760px) {
      display: block;
    }
  }
  .detaildiv {
    margin-bottom: 30px;
    width: 100%;
    display: block;
    @media (max-width: 760px) {
      margin-bottom: 0;
    }
    .detailheader-region {
      width: 35%;
      text-align: right;
      display: inline-block;
      margin-right: 30px;

      @media (max-width: 760px) {
        width: 100%;
        margin: 0px;
        text-align: center;
        margin-bottom: 10px !important;
      }
      .detailheader {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 14px;
        color: $black;
      }
      .flightname {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 13px;
        color: #8f8f8f;
      }
      .duration {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 14px;
        color: $black;
      }
    }
    .detailtext-region {
      width: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 760px) {
        margin: 0px;
        text-align: center;
        box-sizing: border-box;
        padding: 0px !important;
        width: initial;
        justify-content: center;
      }
      img {
        width: 22px;
        height: 16px;
        display: inline-block;
        margin: 0 20px;
      }
      .contact {
        font-family: $fontmedium;
        font-weight: 500;
        font-size: 14px;
      }
      .durationinfo {
        display: inline-block;
        width: 50%;
        font-family: $fontmedium;
        font-weight: 500;
        span {
          white-space: nowrap;
        }
        .time {
          font-size: 14px;
          color: $black;
          font-family: $fontmedium;
          font-weight: 500;
        }
        .date {
          font-size: 14px;
          font-weight: 500;
          color: $black;
        }
        .place {
          font-weight: 500;
          font-size: 14px;
          color: #919191;
        }
        @media (max-width: 760px) {
          width: 50%;
          margin: 0 10px;
        }
      }
      .fromtoinfo {
        span {
          white-space: nowrap;
          display: block;
          text-align: center;
        }
        .short-name {
          font-weight: 500;
          font-size: 14px;
          color: $black;
        }
        .long-name {
          font-weight: 500;
          font-size: 14px;
          color: #919191;
        }
      }
    }
  }
  .bookingdetail {
    .bookingdate-half {
      display: flex;
      justify-content: space-around;
    }
    .bookingdiv {
      margin-bottom: 15px;
      .bookingheader {
        font-family: "Roboto", Myanmar3 !important;
        font-weight: 700;
        font-size: 14px;
        display: block;
      }
      .bookingid {
        font-family: "Roboto", Myanmar3 !important;
        font-weight: 700;
        font-size: 20px;
        color: #00a651;
      }
      .bookingtext {
        font-family: "Roboto", Myanmar3 !important;
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 760px) {
  .confirmation-lower {
    width: calc(100% - 20px) !important;
    margin-top: 0px !important;
    padding: 0px 10px !important;
    box-sizing: border-box;
    margin: 0px 10px;
    margin-left: auto;
    margin-right: auto;
    .bookingdetail {
      .bookingdiv {
        text-align: center !important;
        .bookingheader {
          line-height: 30px;
          font-weight: 700;
        }
      }
    }
  }
  .confirmation-lower .total {
    width: 100% !important;
    margin-left: -30px !important;
    margin-top: 0px !important;
  }
  .confirmation-upper {
    background-color: transparent;
  }
  .confirmation-upper .title {
    font-size: 16px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
  }
  .confirmation-upper .bodytext {
    font-size: 14px !important;
  }

  .detaildiv {
    text-align: center;
    padding: 20px 0px;
  }

  .detailheader {
    font-size: 14px;
    color: $black;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .short-name {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 25px;
    color: $black;
  }
  .long-name {
    font-family: "Roboto" !important;
    font-weight: 500;
    font-size: 13px;
    color: #8f8f8f;
  }
  .confirmation-lower img {
    width: 30px !important;
  }
  .confirmation-upper {
    padding: 20px 0px 0px 0px;
    img {
      margin: 0px !important;
    }
  }
  .detailtext-region {
    width: 100%;
    margin-top: 0px !important;
    padding: 20px !important;
    margin-top: 20px;
    img {
      margin: 10px 20px 10px 15px;
    }
  }
  .emailContact {
    margin-top: 30px;
  }
  .time {
    font-size: 16px;
    color: $black;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
  }
  .bookingtext {
    color: #8f8f8f;
    font-family: "Roboto" !important;
    font-weight: 500;
    font-size: 13px;
  }
  .durationinfo {
    img {
      margin: 0px !important;
    }
  }
  .bgWhite {
    background-color: #fff;
    padding-bottom: 50px;
  }
  .playArrow {
    width: 10px;
    padding: 6px !important;
  }
  .imgOperator {
    border: 1px solid #e6e7e8;
    border-radius: 5px;
    margin: 5px;
  }
}
.confirmation-lower .bookingdetail .bookingdiv.textCenter {
  text-align: center !important;
  padding-top: 10px !important;
}
.busConfirmation {
  margin-top: 20px !important;
}
