@media (max-width: 780px) {
  .web-from-to {
    display: none !important;
  }

  .dateRange {
    display: none;
  }
  body {
    background-color: #f4f3f4;
  }
  .loadingWrapper {
    width: 110% !important;
  }
  .clickAwayDiv {
    float: none;
    display: inline;
  }
  .homeContainer {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    // padding: 0px 20px !important;
    padding: 0 !important;
    height: 100vh;
    .DateInput_input {
      font-size: 18px !important;
      text-align: right;
      float: right;
    }
    .DateInput_input__focused {
      width: 150px;
      margin-left: 150px;
      margin-top: -40px !important;
      position: absolute;
    }
  }
  .mobile-from-to {
    margin-top: 20px;
    display: block !important;
    label {
      float: left;
      width: 19%;
      font-size: 18px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }
    .MuiInputBase-input {
      width: 100%;
      float: right;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 700;
      color: #231f20;
      font-size: 18px;
    }
    .MuiFormControl-root {
      width: 80%;
    }
  }
  .myanmar {
    font-size: 53px !important;
    line-height: 30px !important;
  }
  .menu {
    min-height: 70px !important;
    padding-left: 20px !important;
    span {
      font-size: 20px !important;
      float: right;
      margin-left: 20px;
    }
  }
  .search {
    // height: 290px;
    height: 240px !important;
    padding: 0px !important;
    margin-top: -45px !important;
  }
  .menuList {
    display: none;
  }
  .container {
    max-width: 100% !important;
  }
  .searchIcon {
    display: none !important;
  }
  .btnBlueSearch {
    display: block !important;
    margin-top: -15.7vh !important;
    button {
      width: 100%;
      background-color: #01b7f2 !important;
      margin-top: -20px !important;
      text-transform: capitalize !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 500;
      font-size: 17px !important;
      height: 50px;
    }
    .btnDisable {
      background-color: #01b7f2 !important;
    }
  }
  .btnSearch {
    display: none !important;
  }
  #fl-home-btn-search span,
  #ia-fl-home-btn-search span {
    pointer-events: none;
  }
  .menu {
    float: none !important;
    text-transform: uppercase;
  }
  .showMenu {
    width: 300px;
    .MuiDrawer-paper {
      background-color: #000;
      opacity: 0.6;
      width: 290px;
      height: auto;
      top: 80px !important;
    }
  }
  .MuiBackdrop-root {
    top: 50px !important;
  }
  .homeMenu {
    float: left;
    top: 15px !important;
    padding: 0px !important;
    min-width: 12px !important;
    float: left;
    margin-top: 10px;
    opacity: 1 !important;
  }
  .checkReturnDate,
  .calendarIcon {
    opacity: 0 !important;
  }
  .menuIcon {
    float: left;
    min-width: 30px !important;
    // margin-top: 17px !important;
    width: 48px;
    height: 48px;
    margin-left: -12px !important;
    margin-top: 5px !important;
    // z-index: 1500;
    .MuiButton-root {
      min-width: 30px !important;
    }
    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }
  .chooseReturnDate {
    opacity: 1 !important;
    margin-left: 0px !important;
  }
  .departfrom {
    height: 60px;
    border-bottom: 1px solid #e4e4e4;
    margin: 0px !important;
    padding: 0px 20px !important;
    min-width: 100% !important;
    position: relative;
  }
  .departfrom {
    .MuiInputBase-input {
      text-align: right;
    }
  }

  .MuiFormControl-root label {
    margin-top: -25px;
  }
  .MuiInputLabel-shrink {
    transform: none !important;
    line-height: 70px !important;
  }
  .datepicker {
    padding: 0px 20px !important;
  }
  .returnFlight {
    margin-top: 30px !important;
    float: none !important;
    margin-left: 0px !important;
  }
  .departFlight {
    margin-top: -8px !important;
  }
  .airportList {
    padding: 10px 15px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: #00aeef;
    line-height: 20px;
  }
  .departfrom {
    .MuiTextField-root {
      width: 100%;
    }
    .MuiInputBase-root {
      margin-top: 0px !important;
    }
  }
  .popupSearch {
    height: 95px;
    background-color: #00aeef;
    position: sticky;
    top: 0;
    .searchTitle {
      color: #ffffff;
      font-family: "Roboto", Myanmar3;
      font-weight: 500;
      line-height: 40px;
      padding-top: 2px;
      margin-left: 40%;
      font-size: 16px;
    }
    .MuiTextField-root {
      width: 93%;
      margin-top: -5px !important;
    }
    .MuiInputBase-input {
      text-align: left !important;
      padding: 10px 15px !important;
      border-radius: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    .MuiInputBase-input::placeholder {
      font-family: "Roboto", Myanmar3;
      font-weight: 400;
      font-size: 14px;
    }
    .MuiInputBase-root {
      left: 13px !important;
      margin-top: 10px !important;
    }
    .MuiButton-text {
      margin-left: -6px;
    }
    input {
      float: left;
      background-color: #fff;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: -30px;
    }
  }
  .topCity {
    height: 50px;
    color: #000000;
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    background-color: #e4e4e4;
    padding: 10px;
    margin-bottom: 15px !important;
    line-height: 30px;
  }
  .passenger {
    padding-top: 15px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    border-radius: 5px;
    .MuiInputAdornment-root {
      display: none;
    }
    label {
      float: left;
      width: 15%;
      font-size: 18px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }
  }

  .slideContainter {
    position: fixed;
    overflow-x: hidden;
    top: 0px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 1501;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-radius: 0px !important;
  }
  .closeAiport {
    float: left;
    margin-top: 5px !important;
    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }
  .airportSearchIcon {
    color: #2699fb !important;
  }
  .mdatepicker .SingleDatePicker {
    width: 100% !important;
    text-align: left;
  }
  .fieldLbl {
    float: left;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .mdatepickerReturn .SingleDatePicker {
    text-align: left;
    margin-left: 3px;
    width: 80%;
    .DateInput_input {
      margin-left: 22px;
    }
  }
  .SingleDatePickerInput_calendarIcon {
    margin: 0px !important;
    padding: 0px !important;
  }
  .mdatepickerReturn {
    margin-left: 3px !important;
  }
  .mdatepickerReturn .DateInput {
    width: 72% !important;
  }
  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 26%;
  }
  .mdatepicker .SingleDatePickerInput_calendarIcon {
    width: 47%;
  }

  .mdatepicker .SingleDatePickerInput__withBorder {
    width: 100% !important;
  }
  .mdatepicker label,
  .mdatepickerReturn label {
    width: 13% !important;
  }
  .mdatepicker {
    .DateInput {
      width: 52% !important;
      line-height: 50px !important;
      float: none !important;
    }
  }
  .mdatepickerReturn {
    padding-right: 3px !important;
    .DateInput {
      width: 75%;
      line-height: 50px !important;
      float: none !important;
    }
  }
  .mdatepicker,
  .mdatepickerReturn {
    height: 59px;
    margin: 0px !important;
    border-bottom: 1px solid #ddd;
    padding: 0px !important;

    .DateInput_input__focused {
      width: 0% !important;
    }
    .SingleDatePickerInput__withBorder {
      border: 0px !important;
      line-height: 29px;
      max-height: 59px;
    }

    .DateInput_input {
      padding: 5px 0px !important;
    }
    label {
      float: left;
      height: 60px;
      width: 25%;
      font-size: 16px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }
    .DateInput__disabled,
    .SingleDatePickerInput__disabled {
      background: #fff !important;
    }
    .DateInput__disabled {
      width: 70% !important;
    }
  }
  .editForm {
    width: 100%;
  }
  .searchBox {
    .MuiInputBase-input {
      width: 100% !important;
    }
  }
}
@media (min-width: 400px) {
  .mdatepickerReturn label {
    width: 13% !important;
  }
  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 62px !important;
  }
  .mdatepickerReturn .SingleDatePicker .DateInput_input {
    width: 73% !important;
  }
  .mdatepickerReturn .DateInput {
    width: 75% !important;
  }
  .MuiContainer-root {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
@media (max-width: 420px) {
  .MuiContainer-root {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
@media (max-width: 370px) {
  .departfrom {
    padding: 0px 15px !important;
  }
  .infoSection br {
    display: none;
  }
  .btnEditSearch span {
    font-size: 16px !important;
  }
  .infoSection .airlnelbl {
    position: relative !important;
  }
  .mobile-from-to label {
    width: 22% !important;
  }
  .mdatepicker .SingleDatePicker {
    width: 100% !important;
  }
  .mdatepicker .SingleDatePickerInput_calendarIcon {
    width: 41% !important;
    .fieldLbl {
      width: 105px !important;
      text-align: left;
    }
  }
  .mdatepicker .DateInput {
    width: 59% !important;
  }
  .mdatepickerReturn .DateInput {
    width: 68% !important;
  }
  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 32% !important;
  }
  .mdatepickerReturn .SingleDatePicker {
    width: 80% !important;
  }
  .mdatepickerReturn label {
    width: 12% !important;
    margin-left: -3px !important;
  }
  .btnBlueSearch {
    margin-top: -7vh !important;
  }
  .homeContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .airlineImg {
    width: 92px;
    height: 30.5px;
    padding: 0 5px;
  }
  .footer {
    .container .MuiGrid-container {
      padding: 0 20px;
      margin-bottom: 15px;
    }
  }
}
.returnMobileText {
  margin-left: -31px !important;
}
@media (max-width: 375px) {
  .search {
    margin-bottom: 3px !important;
  }
  .returnText {
    font-size: 18px !important;
  }
  .stayText {
    font-size: 14px !important;
  }
  .confirmationBox button span {
    font-size: 12px !important;
  }
}
.mobileTripType div {
  padding: 15px 10px !important;
}
@media (max-width: 780px) {
  #BackgroundContainer .search {
    margin-top: 30px !important;
  }
}

/* new UI for round trip type */
@media (max-width: 780px) {
  .tripTypeButton {
    width: 50% !important;
    float: left !important;
    font-family: "Roboto Regular" !important;
    font-size: 16px !important;
    line-height: 42px !important;
    text-align: center !important;
    border-color: 2px solid transparent;
    color: #231f20 !important;
    opacity: 0.2 !important;
    cursor: pointer !important;
  }
  .tripTypeButton span {
    width: 100% !important;
    display: inline-block !important;
  }
  .oneWayTripTypeButton {
    border-top-left-radius: 4px !important;
  }
  .activeTripTypeButton {
    background: #ffffff !important;
    font-family: "Roboto Regular" !important;
    color: #00aeef !important;
    border-bottom: 2px solid #00aeef !important;
    height: 43px !important;
    opacity: 1 !important;
  }
  .activeTripTypeButton span {
    font-weight: 700 !important;
  }
  .nonActiveTripTypeButton {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }
  .roundTripTypeButton {
    border-top-right-radius: 4px !important;
  }
  #BackgroundContainer .search {
    margin-top: -5vh !important;
  }
  .departfrom {
    height: 45px !important;
    margin-top: -15px !important;
  }
  .mdatepicker .SingleDatePicker .SingleDatePickerInput {
    line-height: 30px !important;
  }
  .mobile-from-to {
    margin-top: 10px !important;
  }
  .fieldLbl {
    line-height: 30px !important;
  }
  .mdatepicker,
  .mdatepickerReturn {
    border-bottom: none !important;
  }
  .passenger {
    padding-top: 7px !important;
    font-size: 16px !important;
  }
  #BackgroundContainer .swapDestination {
    left: 25% !important;
    top: -23px !important;
  }
  .swapDestination.MuiSvgIcon-root {
    font-size: 1.2rem !important;
    transform: rotate(90deg) !important;
  }
  .mobile-from-to label,
  .passenger label,
  #BackgroundContainer .fieldLbl {
    font-size: 14px !important;
  }
  .mobile-from-to .MuiInputBase-input,
  .homeContainer .flightSearch .DateInput_input {
    font-size: 18px !important;
  }
  .homeContainer {
    height: 107vh !important;
  }
  .infoSection span {
    display: block !important;
  }
}
@media (max-width: 370px) {
  .passengerType .MuiFormControlLabel-root {
    margin-right: -5px !important;
  }
}
/* new UI design for depart flight div at flight result page */
/* new UI for flight details of customer information page */
@media only screen and (max-device-width: 780px) {
  .custDetailTitle {
    border-bottom: none !important;
  }
  .continueBox {
    background: none !important;
    border: none !important;
  }
}
/* new UI for flight details of customer information page */
@media (max-width: 780px) {
  .btnBlueSearch button {
    height: 60px !important;
  }
  #fl-home-btn-search span {
    font-weight: 500 !important;
  }
}
.selectText {
  color: #ffffff !important;
}
@media (max-width: 780px) {
  .airportList {
    color: #000000 !important;
  }
  .airportList span {
    color: #a5a7a6 !important;
  }
  .mobile-from-to label {
    width: 20% !important;
  }
}
@media (max-width: 320px) and (max-height: 568px) {
  .mobile-from-to label {
    width: 25% !important;
  }
}
@media (max-width: 780px) {
  .departfrom {
    padding: 0 16px !important;
  }
  .btnContinue {
    height: 60px !important;
  }
  .mobileConfirmBtn {
    height: 37px !important;
  }
  .textAlignLeft {
    text-align: left !important;
  }
  .textAlignRight {
    text-align: right !important;
  }
  .mobileBusConfirmButton {
    height: 45px !important;
  }
}
@media (max-width: 320px) {
  .search {
    height: 270px !important;
  }
}
