.car-home {
  .date-region {
    .date-inner {
      .DateInput {
        width: 170px;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }
}
.car-personal-info,
.car-home {
  .date-region {
    .date-inner {
      width: 889px !important;
      margin: auto;

      .SingleDatePickerInput__withBorder {
        border: 0px;
        border-radius: 5px;

        border: 1px solid #dbdbdb !important;
      }
      .DateInput_input {
        padding: 0px;
        color: #000;
        font-size: 14px !important;
        border-bottom: 0px;
        cursor: pointer;
      }
      .SingleDatePickerInput_calendarIcon {
        margin: 0px;
        background-color: #ebebeb;
        padding: 10px 15px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        svg {
          margin-top: -5px;
          fill: #000;
        }
      }
      .DateInput_input__focused {
        border-bottom: 0px;
      }
      .CalendarDay__blocked_out_of_range {
        color: #d3d3d3 !important;
        background-color: transparent !important;
        pointer-events: none;
      }
      .MuiInputBase-formControl {
        border: 1px solid #ebebeb;
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;
        input {
          color: #000;
        }
      }
      .MuiCard-root {
        position: absolute;
        z-index: 999;
        padding: 10px;
        border-radius: 5px !important;
        width: 201px;
        min-height: 80px;
        span {
          width: 100%;
          font-family: "Roboto", Myanmar3 !important;
          display: inline-block;
          cursor: pointer;
          margin-bottom: 10px;
        }
        span:hover {
          color: #00aeef;
        }
      }
    }
    .start-date,
    .end-date {
      .SingleDatePicker_picker {
        top: 75px !important;
        left: -68px !important;
      }
      .DateInput_fang {
        top: 35px !important;
      }
    }
  }
  .pickupLocation,
  .dropoffLocation {
    width: 93%;
  }
  .carStartDate .SingleDatePicker {
    width: 100%;
    display: inline;
  }
  .date-inner {
    .SingleDatePicker {
      padding-left: 0px !important;
      .DateInput__disabled {
        background-color: #f2f2f2 !important;
      }
    }
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: #f2f2f2 !important;
  }
  .contactInfo .MuiGrid-item {
    padding-bottom: 15px;
  }
  .contactInfo {
    & > .MuiGrid-item:nth-child(even) {
      padding-left: 15px;
    }
  }
}
.car-personal-info {
  .date-region .date-inner.car-date {
    width: 100% !important;
    .fieldTitle {
      display: inline-block;
      width: 100%;
    }
    .DateInput {
      width: 140px;
      padding: 5px 10px;
      border-radius: 5px;
    }
    .start-date-time-region {
      display: flex;
      .rc-time-picker {
        width: 100px !important;
        .rc-time-picker-input {
          color: #231f20;
          height: 37px !important;
          outline: none;
          font-size: 14px;
        }
        .rc-time-picker-clear {
          top: 5px !important;
        }
      }
    }
    .start-date-time-region.start-time {
      .SingleDatePicker {
        width: 70%;
      }
    }
    .start-date-time-region.end-time {
      .SingleDatePicker {
        width: 80%;
      }
    }
    .errMessage {
      font-weight: 400;
      color: #ff0000;
      font-size: 12px;
    }
  }
}
.selectBox.carItemSelect {
  max-width: 100% !important;
}
.car-list {
  width: 100%;
  border-collapse: collapse;
  td {
    min-height: 40px;
    height: 40px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
.date-region .date-inner.car-date {
  max-width: 1000px !important;
  width: 1000px !important;
}
.col-car-reserve {
  width: 40%;
  .btnChoose {
    margin-top: 0px !important;
  }
}
.rc-time-picker-input {
  height: 33px;
}
.rc-time-picker {
  width: 77px !important;
  height: 30px;
}
.car-personal-info
  .date-region
  .date-inner
  .SingleDatePickerInput_calendarIcon {
  padding: 7px 15px !important;
}
.car-personal-info {
  .date-region .date-inner .SingleDatePickerInput__withBorder,
  .date-region .date-inner .SingleDatePickerInput_calendarIcon {
    height: 35px;
  }
  .MuiSvgIcon-root {
    color: #00aeef !important;
  }
  .btnContinue {
    width: 147px !important;
  }
}
.car-terms {
  text-align: left;
  line-height: 25px;
  font-size: 14px;
}
.mobile-rc-picker {
  width: 100px;
  .rc-time-picker-input {
    height: 40px !important;
    margin-top: 12px;
  }
  .rc-time-picker-clear {
    height: 40px;
    top: 20px;
  }
}
.termsBox {
  max-height: 74vh;
  overflow: scroll !important;
  width: 50vw;
  top: 10% !important;
  left: 25vw !important;
}
@media (max-width: 760px) {
  .termsBox {
    left: 0px !important;
  }
}

.car-personal-info-mobile {
  .pickupLocationMobile,
  .dropOffLocationMobile {
    width: 100% !important;
    border: 1px solid #e6e7e8 !important;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .dropOffMobile {
    margin-bottom: 20px;
  }
  .date-region .date-inner.car-date {
    .rc-time-picker {
      width: 100px !important;
      margin-left: 10px;
      .rc-time-picker-input {
        color: #231f20;
        height: 37px !important;
        outline: none;
        font-size: 14px;
      }
      .rc-time-picker-clear {
        top: 5px !important;
      }
    }
    .errMessage {
      font-weight: 400;
      color: #ff0000;
      font-size: 12px;
      width: 100%;
      display: inline-block;
    }
  }
}
