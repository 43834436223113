$searchtextcolor: #a5a7a6;
$white: #fff;

#root .__mm,
#root .__zg {
  font-family: Myanmar3 !important;
}

@media (max-width: 700px) {
  body {
    background-color: #f4f3f4;
  }
}

body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  // background-color: #fafafa;
  // overflow-y: scroll;
  background-color: rgb(39, 49, 66);
}
.logo {
  width: 180px;
  padding-top: 10px;
}
.MuiContainer-root {
  padding: 0px !important;
}
.menu {
  float: left;
  font-family: "Roboto", Myanmar3;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff !important;
  padding-right: 27px !important;
  padding-left: 0px !important;
  float: left;
  a {
    font-family: "Poppins", Myanmar3;
    font-weight: 400;
    font-size: 16px !important;
    font-weight: normal !important;
    color: #fff !important;
    text-decoration: none !important;
    width: 100%;
    span,
    img {
      float: left;
      font-size: 18px !important;
    }
  }
}
.menu:hover {
  background-color: transparent !important;
}

.menuList {
  padding: 0px 12px 0px !important;
}
.myanmar {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 700;
  font-size: 110px;
  color: #fff;
  line-height: 60px;
  position: relative;
}
.search {
  background-color: #fff;
  height: 74px;
  border-radius: 5px;
  // margin-top: -30px !important;
  padding: 0px 0px 0px 30px;
}
.nopadding {
  padding: 0px !important;
}

.pull-right {
  float: right !important;
}
.searchIcon {
  width: 26px;
  height: 26px;
  margin-top: 24px;
  padding-right: 10px;
  float: left;
}
.btnSearch {
  max-width: 120px !important;
  max-height: 74px !important;
  button {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    font-size: 18px !important;
    text-transform: capitalize !important;
    height: 100%;
    border-radius: 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    box-shadow: none !important;
    width: 120px !important;
    margin-right: -1px;
    min-height: 73.5px;
  }
  .btnActive {
    background-color: #01b7f2 !important;
  }
}
#fl-home-btn-search span {
  pointer-events: none;
}
.MuiFormLabel-root.Mui-focused,
.selectedAiport {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.MuiFormLabel-root {
  color: $searchtextcolor !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.container {
  width: 1200px;
  max-width: 1200px;
  margin: auto;
}

.homeContainer {
  // width: 1200px;
  max-width: 1200px;
  // min-width: 1200px;
  padding: 0px 50px;
  height: 100vh;
  margin: auto;
  .DateInput {
    width: 100% !important;
  }
  .DateInput_input {
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 95% !important;
    padding: 7px 11px 9px;
    float: left;
  }
  .flightSearch .DateInput_input {
    font-size: 18px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20 !important;
    width: 90% !important;
    padding: 7px 11px 9px;
    float: left;
  }
  .DateInput__disabled {
    width: 0px !important;
  }
  .DateInput_1 {
    #start_date_id {
      position: inherit;
      padding: 7px 13px 7px 7px;
      min-width: 200px;
    }
    #end_date_id {
      padding: 7px 22px 7px 0px;
      margin: auto;
      margin-top: 5px;
      width: 0px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #start_date_id {
      padding: 10px 2px 7px 7px !important;
      min-width: 130px !important;
    }
  }
  .flightSearch .DateInput_1 {
    #return_start_date_id {
      padding: 10px 2px 7px 7px !important;
    }
  }
  .selected #end_date_id {
    margin-top: -10px !important;
  }
  .selected .DateRangePickerInput {
    padding-top: 0px !important;
  }

  .DateInput_input::placeholder {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: $searchtextcolor !important;
    padding: 0px 11px 9px;
  }

  .DateRangePickerInput,
  .DateInput_input__focused {
    border: none !important;
    width: 100%;
    padding-top: 13px;
  }
  .DateInput {
    line-height: 40px !important;
    // width: 50% !important;
    float: left;
  }
}
.DateRangePicker {
  width: 80%;
  height: 30px;
}
.DateInput_fang {
  bottom: 70px !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__default:hover {
  background: #00aeef !important;
  border: none !important;
  color: #fff !important;
  border-radius: 3px;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected_span {
  background: #e5f7fb !important;
  border: none !important;
  color: #000 !important;
}
.CalendarDay__default {
  border: none !important;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff !important;
  color: #cacccd !important;
  border: none !important;
}
.DateRangePickerInput__disabled,
.DateInput_input__disabled,
.DateInput__disabled {
  background: #fff !important;
}
#tripType {
  margin-left: 0px;
  min-width: 130px;
  cursor: pointer;
}
.cities-popup.return-popup {
  left: 0% !important;
  padding: 5px !important;
  .MuiGrid-item {
    padding: 0px !important;
  }
  span {
    padding: 10px !important;
    display: block;
  }
}
div[id^="__lpform_"] {
  display: none;
}
.searchBox {
  #fromAirport,
  #toAirport {
    height: 1.3875em !important;
  }
}
.MuiInputBase-input {
  padding: 0px !important;
}
.Mui-focused,
.MuiFormLabel-filled {
  line-height: 36px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  .MuiSvgIcon-root {
    color: #01b7f2 !important;
  }
}
.MuiSvgIcon-root,
.MuiFormControlLabel-label {
  color: #fff !important;
}
.focusedCalendar {
  margin-top: -15px;
}
.DateRangePickerInput_calendarIcon {
  padding: 0px !important;
  margin: auto !important;
}
.hire {
  margin-top: 15px !important;
  & > span {
    font-family: "Poppins", Myanmar3 !important;
    font-weight: 500;
    display: block;
    font-size: 30px;
    font-weight: 500 !important;
    text-align: center;
  }
  span {
    // font-size: 1.1333em;
    line-height: 1.25em;
    font-weight: 600;
    color: #000 !important;
  }
}
@media (min-width: 600px) {
  .hire.MuiGrid-grid-sm-6 {
    max-width: unset;
    flex-basis: 100%;
  }
}
@media (min-width: 1300px) {
  .airport-popup {
    margin-left: 20% !important;
  }
}
@media (min-width: 781px) {
  .searchBox {
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
  }
}
@media (min-width: 800px) {
  .search {
    .searchRadioGroup {
      display: flex;
      justify-content: flex-start;
      position: relative;
      bottom: 1px;
    }
  }
}

.airport-popup,
.cities-popup {
  //width: 600px;
  width: 300px;
  margin-left: 12%;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
  .MuiGrid-item {
    padding: 7px 12px !important;
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }
  .MuiGrid-item:hover {
    color: #00aeef !important;
    cursor: pointer;
  }
}

.top.airport-popup:after,
.top.cities-popup:after {
  content: "";
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  left: 15%;
  margin-left: -12px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: -3px;
}
.top.cities-popup.return-popup:after {
  margin-top: -7px !important;
}
.bottom.airport-popup:after,
.bottom.cities-popup:after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #fff;
  position: absolute;
  border: 1px solid #e6e7e8;
  z-index: -1;
  top: -3px;
  left: 15%;
  position: absolute;
}
.cities-popup {
  width: 200px !important;
  left: -30% !important;
  margin-left: 0% !important;
}

.flightType {
  background-color: #01b7f2;
  color: #fff;
  border-radius: 15px;
  padding: 7px 11px;
  width: 60px;
  float: left;
  font-size: 14px;
  line-height: 15px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
}
.MuiInputBase-root {
  color: #231f20 !important;
}
.selectedAirport {
  color: #000000 !important;
  line-height: 30px;
  padding-left: 10px;
  line-height: 30px;
  padding-left: 10px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  font-size: 16px;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}
.footer {
  color: white;
  background-color: #2e2e2e;
  min-height: 50px;
  & > .container {
    margin: 0;
    width: 100%;
    max-width: unset;
  }
  .container .MuiGrid-container {
    max-width: 1200px;
    width: 100%;
    margin: 40px auto 0px;
    justify-content: space-around;
  }
}
//Medium break points
@media (max-width: 960px) {
  .footer .container .MuiGrid-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.bgWhite {
  background-color: $white;
}
.maillist {
  color: $white;
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-style: italic;
}
.contact-details {
  padding-bottom: 20px;
  padding-top: 10px;
}
.departfrom {
  width: calc(33.3333% - 39.99996px);
  max-width: calc(33.3333% - 39.99996px) !important;
  min-width: calc(33.3333% - 39.99996px);

  .MuiTextField-root {
    margin-top: 20px !important;
    width: 70%;
  }
  .MuiInputBase-root {
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: "Roboto", Myanmar3 !important;
    margin-top: 15px !important;
    label {
      margin-top: 5px;
    }
  }
  .MuiInputLable-animated {
    margin-top: -5px !important;
  }
}
@media (min-width: 780px) {
  .flightSearch .departfrom {
    // max-width: calc(33.34% - 40px) !important;
    // min-width: calc(33.34% - 40px) !important;
    max-width: calc(33.36% - 40px) !important;
    min-width: calc(33.36% - 40px) !important;
    max-height: 73px !important;
  }
  .flightSearch .btnSearch {
    margin-top: -1px !important;
  }
  .flightSearch.hasReturn .departfrom {
    max-width: calc(25% - 30px) !important;
    min-width: calc(25% - 30px) !important;
  }
  .flightSearch.hasReturn .btnSearch {
    margin-top: -0.8px !important;
  }
}
.returnTrip {
  padding-left: 10px !important;
}
.MuiFormControl-root {
  label {
    margin-top: -12px;
  }
}
.MuiFormControl-root {
  .MuiInputLabel-shrink {
    margin-top: -5px !important;
    transform: translate(0, -5.5px) scale(0.85) !important;
  }
}
.departureDate {
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.block {
  display: block !important;
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #a5a7a6 !important;
  opacity: 1;
  transform: translate(0, 1.5px) scale(1);
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-top: 8px;
}
.departFlight {
  font-family: "Poppins", Myanmar3 !important;
  font-weight: 400;
  float: left;
  margin-left: 8px;
  width: 37%;
  min-width: 100px;
  margin-top: 4px;
}
.returnFlight {
  float: right;
  margin-left: 50px;
  width: 45%;
}
.hide {
  display: none !important;
}
@media (min-width: 700px) {
  .mobile-from-to {
    display: none !important;
  }
}
.DateInput_input__focused::placeholder {
  opacity: 0 !important;
}
.alice-carousel__stage-item {
  opacity: 0.5;
  width: 200px;
}
.alice-carousel__stage-item:hover {
  opacity: 1 !important;
}
.airlineImg {
  width: 184px;
  height: 61px;
  padding: 20px 0;
}
.pull-left {
  float: left;
}
.clickAwayDiv {
  width: 100%;
  float: left;
  height: 100%;
}
.flightSearch .clickAwayDiv {
  width: 87% !important;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  left: 25px;
  border-right: 9px solid #4b515d;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  top: 28px;
  line-height: 0.78;
  right: 25px;
  border-left: 9px solid #4b515d;
}
.DayPicker_weekHeader {
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  font-size: 14px !important;
}
.DateRangePicker_picker {
  border-radius: 5px;
  left: -180px !important;
  bottom: 68px !important;
  z-index: 10 !important;
}
.checkReturnDate {
  margin-top: 13px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}
.selectedCheckReturn {
  margin-top: 23px !important;
  margin-left: 240px !important;
  z-index: 999;
  position: absolute !important;
  height: auto !important;
}
.btnBlueSearch {
  display: none !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pull-left {
  float: left;
}
.homeMenu,
.chooseReturnDate {
  opacity: 0 !important;
}
.datepicker {
  margin: auto;
  max-height: 74px;
  position: relative;
}
.textRight {
  text-align: right !important;
}
.dateRange {
  width: -webkit-calc(0.333333 * (100% - 120px));
  width: -moz-calc(0.333333 * (100% - 120px));
  width: calc(0.333333 * (100% - 120px));
  max-width: calc(0.333333 * (100% - 120px)) !important;
  min-width: calc(0.333333 * (100% - 120px));
  //margin: auto 0px !important;
}

.DateRangePickerInput_arrow {
  display: none !important;
}
.calendarIcon {
  // position: absolute;
  z-index: 30;
  line-height: 50px;
  margin: 24px 0px;
  width: 26px;
  height: 26px;
  float: left;
}
.flightSearch .calendarIcon {
  margin: 22px 0px;
}
.selected-calendar {
  margin-top: 24px !important;
}
.dividerContainer {
  margin-top: 5px !important;
}
.loadingWrapper {
  margin: 20px auto;
  width: 889px !important;
  text-align: left;
  background-color: #fff;
  padding: 20px;
}
.btnDisable {
  background-color: #01b7f2 !important;
  pointer-events: none;
}

.noborder {
  border: none !important;
}
.airport-popup {
  .MuiGrid-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(10, auto);
    height: auto !important;
    .MuiGrid-grid-xs-4 {
      max-width: 100% !important;
    }
  }
}

.MuiGrid-item.page404 {
  display: block;
  background-color: #273142;
  position: fixed !important;
  text-align: center;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  .title {
    font-family: "Roboto", Myanmar3;
    font-weight: 700;
    font-size: 80px;
    color: #fff !important;
    display: inline-block;
    margin-top: 20vh;
  }
  .warning {
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    font-size: 30px;
    color: #919191;
  }
}
.CalendarDay__default.CalendarDay__today {
  color: #0041ff !important;
  border: 2px solid #a7a7a7 !important;
  border-radius: 3px;
}
.MuiMenuItem-root {
  overflow: inherit !important;
  .searchBox {
    .MuiInputBase-input {
      width: 90% !important;
    }
  }
}
.disableButton {
  opacity: 0.3 !important;
  cursor: default !important;
}
span,
label,
.MuiFormLabel-root,
.departFlight {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
}
.selected--flag--option,
.arrow-down {
  color: #fff !important;
}
.__react_component_tooltip {
  z-index: 99999 !important;
}
//TABLE
@media (min-width: 769px) and (max-width: 1023px) {
  .homeContainer {
    .menuList {
      position: absolute;
      right: 0;
    }
  }
}
@media (min-width: 800px) {
  .calendarImgDiv {
    background-image: url("../images/sprite-images.png");
    background-position: 1px -142px;
    background-size: 25px;
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 28px;
    // margin-right: 15px;
  }
}
.MuiDialog-paperWidthSm {
  min-width: 70% !important;
  position: relative !important;
}
.MuiDialogTitle-root {
  padding: 5px 12px !important;
}
.Component-closeButton-* {
  top: -8px !important;
  right: -5px !important;
  position: relative !important;
}

@media (max-width: 780px) {
  .mdatepicker .SingleDatePicker_picker {
    position: fixed !important;
    top: 45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 10;
    border-radius: 3px;
  }
  #bg2 {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .DateInput_fang {
    display: none !important;
  }
}
@media (min-width: 781px) {
  .CalendarDay__default {
    border: none !important;
  }
}
.tripType {
  margin-right: 5px !important;
}
.returnText {
  font-family: "Poppins";
  font-weight: 300 !important;
  color: #231f20 !important;
  font-size: 28px !important;
  line-height: 32px !important;
}
.stayText {
  color: #8d8d8d !important;
  font-size: 18px !important;
  line-height: 20px !important;
}
#BackgroundContainer a > span {
  font-weight: 400 !important;
}
.flightSearch,
.flightPopup {
  z-index: 10 !important;
}
.homeContainer .flightSearch .DateInput_input {
  font-size: 16px !important;
}
.customerContainer .contactUsFieldBox {
  border: 1px solid #e6e7e8;
  border-radius: 4px;
  width: 100%;
  .MuiInputBase-input {
    height: 30px;
    padding: 5px 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border: none !important;
  }
}
.MuiSnackbar-root {
  left: 7% !important;
  .MuiSnackbarContent-root {
    background-color: #4caf50 !important;
  }
  .MuiSvgIcon-root {
    color: #fff !important;
  }
}
.nopadding-bottom {
  padding-bottom: 0px !important;
}
.contactUsMenu a {
  color: #000 !important;
  padding: 19px;
  font-family: "Roboto", Myanmar3 !important;
  float: right;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.signatureContent {
  padding-top: 50px;
}

#mobileFlightDateRangeLableDiv {
  top: -40px !important;
  position: absolute !important;
  padding-left: 15px !important;
  background: #fff;
  min-height: 50px;
  width: 562px !important;
  padding-top: 10px !important;
  border-radius: 4px !important;
}
.dateLableDividerDiv {
  width: 96% !important;
  margin-top: 10px !important;
  margin-right: 20px !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  height: 1px !important;
  border: none !important;
}
.flightSearch .DayPicker_focusRegion {
  margin-top: 50px !important;
}
#mobileFlightDateRangeLableDiv .flightType {
  width: 68px !important;
  text-align: center !important;
}
.abtDesktopDateDiv .DayPicker_focusRegion {
  margin-top: 0 !important;
}

.CalendarDay__default.CalendarDay__today {
  color: #000000 !important;
  border: none !important;
  border-radius: 6px !important;
}
.CalendarDay__default.CalendarDay__today:hover {
  color: #ffffff !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover,
.CalendarDay__default:hover {
  outline: none !important;
}
@media (max-width: 780px) {
  .DayPicker {
    min-width: 370px !important;
  }
  .abtMobileDateDiv .DayPicker_focusRegion {
    margin-top: 0 !important;
  }
  #mobileFlightDateRangeLableDiv {
    top: -230px !important;
    z-index: 100 !important;
    margin-left: -17px !important;
    width: 100% !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
}
.flightSearch .CalendarDay__today {
  background: #fff !important;
  color: #cacccd !important;
}
.mobileFlightDateRangeLableDiv {
  display: none !important;
}
.btnClose {
  float: right;
  color: #000 !important;
  font-size: 30px;
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}
.cities-popup.sleep-popup {
  width: 400px !important;
  left: 22% !important;
}
.balloonSearchDiv.departfrom {
  line-height: 37px !important;
}
@media (max-width: 780px) {
  .flightSearch .balloonSearchDiv.departfrom.cityPicker {
    min-width: 100% !important;
  }
  .balloonSearchDiv.departfrom {
    line-height: 10px !important;
  }
}
.balloonSearchDiv.departfrom {
  line-height: 25px !important;
}

.spacex-disable {
  opacity: 0.7 !important;
}
.cities-popup .spacex-disable.MuiGrid-item:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

.spacex-noflyMsg {
  color: #fff;
  font-size: 25px;
  font-family: "Roboto" !important;
  font-weight: 700;
  line-height: 35px;
  margin: 0 auto;
  margin-top: 40px;
}
.spacex-search.MuiGrid-item {
  // margin-top: -20vh !important;
  margin: 0 auto;
}

.intercom-lightweight-app-launcher.intercom-launcher {
  opacity: 0;
  pointer-events: none;
}

.date-region {
  .date-inner {
    .SingleDatePicker {
      padding-left: 10px;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 10px 15px !important;
    }
    .SingleDatePicker_picker {
      top: 45px !important;
      left: 10px !important;
    }
    .DayPicker {
      width: auto !important;
      min-width: 0 !important;
    }
    .MuiInputBase-formControl {
      margin-left: 10px;
    }
    .MuiCard-root {
      left: 10px;
    }
  }
}
