.cities-popup:after {
  left: 30% !important;
}
.citiesPopupTooltip {
  z-index: 10 !important;
}
.balloonDetailDiv {
  padding: 5px 10px 0px 10px !important;
}
.SingleDatePicker_picker__portal {
  z-index: 3;
}
.balloonSearchDiv {
  margin-right: 20px !important;
  width: calc(33.3333% - 61.99996px);
  min-width: calc(33.3333% - 61.99996px) !important;
  .DateInput_1 #start_date_id {
    padding-top: 3px !important;
  }
  .departFlight.block {
    line-height: 20px !important;
  }
}
.balloonSearchDiv.departfrom {
  margin-top: 0px !important;
  //line-height: 50px !important;
}
.balloonSearchDiv .airportList {
  line-height: 25px;
  height: 35px;
}
.balloonSearchDiv .topCity {
  line-height: 30px;
  height: 50px;
}
@media (max-width: 780px) {
  .balloonSearchDiv {
    border-right: none;
    margin-right: 0px !important;
    min-width: 100% !important;
    height: 45px !important;
    margin-top: 5px !important;
  }
}
.flightSearch .DayPicker_focusRegion {
  margin-top: 50px !important;
}

@media (min-width: 780px) {
  .mobile-from-to {
    margin-top: 5px !important;
  }
}
.flightSearch .balloonSearchDiv.departfrom {
  width: calc(33.3333% - 59.99996px) !important;
  min-width: calc(33.3333% - 59.99996px) !important;
}
