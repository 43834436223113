.SleepDoorCode {
  background-color: #273142;
  color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.SleepDoorCode .title {
  color: #2cafe8;
}

.SleepDoorCode .booking-id {
  color: #f0f0f0;
  font-weight: 600;
  text-align: center;
}
.SleepDoorCode .booking-id > span {
  color: #2cafe8;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 1px;
  display: inline-block;
  padding: 3px 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-top: 3px;
}
.SleepDoorCode .booking-id a {
  color: #2cafe8;
}

.SleepDoorCode > .wrapper {
  // margin-top: 20vh;
  margin-top: 30px;
  padding: 10px;
}

.SleepDoorCode form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.SleepDoorCode form > input {
  margin: 8px 0;
}

.SleepDoorCode .input-field {
  border: 1px solid #3a3a3a;
  outline: none;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 5px;
}

.SleepDoorCode .btn {
  padding: 6px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background-color: #2cafe8;
  outline: none;
  font-size: 1.1rem;
  margin-top: 10px;
  cursor: pointer;
}
.SleepDoorCode .btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.SleepDoorCode .error-msg {
  color: #ee4d4d;
}

.SleepDoorCode {
  h4.reason-title {
    margin: 5px 0 5px 0;
    font-weight: normal;
    font-size: 17px;
  }
  .reason-text {
    width: 300px;
    outline: none;
    padding: 7px;
    border-radius: 5px;
  }
  .warning-text {
    text-align: center;
    width: 960px;
    margin: 0 auto;
    span {
      color: #f0f0f0;
    }
    .star-text {
      font-size: 13px;
    }
  }
}

@media (max-width: 780px) {
  .SleepDoorCode {
    .warning-text {
      width: 100% !important;
    }
  }
}

#FlyLandginPage,
#FlyCancellation {
  min-height: 100vh;
}

.cancel-header-region {
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  margin: 20px 0;
  span {
    font-size: 14px;
    cursor: pointer;
  }
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .SleepDoorCode,
  #FlyLandginPage,
  #FlyCancellation {
    min-height: 100vh !important;
    min-height: -webkit-fill-available;
  }
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .SleepDoorCode,
  #FlyLandginPage,
  #FlyCancellation {
    min-height: 100vh;
  }
}
.btnCheckoutRes {
  border-radius: 0 !important;
  padding: 10px !important;
  width: 180px !important;
}
.btnCheckoutYes {
  border-radius: 0 !important;
  padding: 10px !important;
  width: 140px !important;
  .MuiCircularProgress-root.MuiCircularProgress-colorPrimary {
    color: #fff !important;
  }
}
.btnCheckoutYes:disabled {
  opacity: 0.7;
}
.btnCheckoutNo {
  border-radius: 0 !important;
  padding: 8px 10px !important;
  width: 140px !important;
  background-color: transparent !important;
  border: 2px solid #00aeef !important;
  margin-left: 10px !important;
}
.btnCheckoutDanger {
  border-radius: 0 !important;
  padding: 8px 10px !important;
  width: 140px !important;
  background-color: #dc004e !important;
  border: 2px solid #dc004e !important;
  margin-left: 10px !important;
  .MuiCircularProgress-root.MuiCircularProgress-colorPrimary {
    color: #fff !important;
  }
}
.btnCheckoutDanger:disabled {
  opacity: 0.7;
}
.btnCheckoutDangerStroke {
  border-radius: 0 !important;
  padding: 8px 10px !important;
  width: 140px !important;
  background-color: transparent !important;
  border: 2px solid #dc004e !important;
  margin-left: 10px !important;
}
