.flightSearch.spacex-sleephome .balloonSearchDiv.departfrom {
  width: calc(44.3333% - 44.99996px) !important;
  min-width: calc(44.3333% - 44.99996px) !important;
}
.flightSearch.spacex-sleephome .DayPicker_focusRegion {
  margin-top: 0px !important;
}
.flightSearch .btnSearch.spacex-sleep-searchBtn {
  margin-top: -0.1px !important;
}
.spacex-sleep-errMsg {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}
.spacex-sleep-wait {
  margin: 0 auto !important;
  margin-top: 30px !important;
}
.spacex-sleep-wait h1 {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 30px;
  color: #01b7f2;
  font-family: "Roboto";
  margin-top: 42%;
}
.spacex-sleep-resultBox {
  margin: 0 auto !important;
  margin-top: 30px !important;
}
.spacex-sleep-resultItem {
  display: inline-table;
}
.spacex-sleep-innerItem {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px !important;
  margin: 10px 20px;
}
.spacex-sleep-innerItem.checked {
  background-color: #ddf7ff;
}
.spacex-sleep-resultItem img {
  width: 100%;
  min-width: 100px;
  min-height: 100px;
}
.spacex-sleep-resultItem span {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 15px;
}
.spacex-sleep-bgHome {
  background-color: #273142;
  //position: fixed;
  //margin: 0;
  //top: 0;
  width: 100%;
  max-width: 100% !important;
  //height: 100%;
  //overflow: auto;
}
.spacex-sleep-bgHome .homeContainer {
  padding-bottom: 20px !important;
  height: auto !important;
  margin-top: 30px;
}
.spacex-sleep-firstCol {
  display: table-cell;
  width: 80% !important;
}
.spacex-sleep-secondCol {
  display: table-cell;
  vertical-align: middle;
  width: 20% !important;
  padding-left: 20px !important;
}
.housing_preference {
  width: 100% !important;
  max-width: 100% !important;
}
.housing_mobile {
  width: 100%;
  display: inline-block;
  margin-top: -15px;
  label {
    color: black !important;
    font-family: "Roboto", Myanmar3 !important;
    font-size: 14px !important;
    display: inline-block;
    width: 100%;
  }
}
//Start Round Checkbox
.roundCheckbox {
  position: relative;
}
.roundCheckbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
.roundCheckbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 15px;
}
.roundCheckbox input[type="checkbox"] {
  visibility: hidden;
}
.roundCheckbox input[type="checkbox"]:checked + label {
  background-color: #01b7f2;
  border-color: #01b7f2;
}
.roundCheckbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
//End Round Checkbox
.spacex-sleep-ctnBtn {
  font-family: "Roboto", Myanmar3 !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  border-radius: 5px !important;
  background-color: #01b7f2 !important;
  padding: 10px 30px !important;
  margin: 0 auto !important;
}
.hrLine {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
}
.btnContinue .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  margin-left: 5px;
}
#sleep-modify-continue {
  width: auto !important;
}

.sleep-dropdown {
  margin: 0 auto !important;

  .familySelect {
    width: 40%;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 7px;
    .MuiInputBase-input {
      width: 90%;
      border-radius: 7px;
      border: 0px;
      font-size: 15px;
      padding: 10px !important;
    }
  }
}

.sleep-customerinfo {
  .familySelect.readonly {
    opacity: 0.5;
  }
  .non-spacex-box {
    span {
      color: #231f20 !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .sleep-avg-work {
    width: 100%;
    border-radius: 7px;
    .MuiSelect-select {
      border: 1px solid #e6e7e8;
      width: 94%;
    }
  }
}
.sleep-customerinfoMobile {
  .non-spacex-box {
    margin-top: -10px;
    margin-bottom: 20px;
    span {
      color: #231f20 !important;
      font-size: 14px;
      font-weight: 400;
    }
    .MuiIconButton-label .MuiSvgIcon-root {
      color: #707070 !important;
    }
  }
  .sleep-avg-work-label {
    font-size: 14px;
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  .sleep-avg-work-mobile {
    width: 100%;
    margin-bottom: 30px;
    .MuiSelect-select {
      width: 98% !important;
      border: 1px solid #e6e7e8;
      border-radius: 7px;
      svg {
        top: 10px;
      }
    }
  }
  .sleep-avg-work-mobile.avg-error {
    margin-bottom: 0;
  }
  .Mui-error.avg-error {
    margin-bottom: 30px !important;
    display: block;
    margin-top: 5px;
  }
}
.familyFieldsMobile {
  .non-spacex-box {
    margin-top: -10px;
    margin-bottom: 20px;
    span {
      color: #231f20 !important;
      font-size: 14px;
      font-weight: 400;
    }
    .MuiIconButton-label .MuiSvgIcon-root {
      color: #707070 !important;
    }
  }
}

.familySelectMobile {
  opacity: 0.5;
}

.sleep-modify-wrapper {
  input:read-only {
    opacity: 0.5;
    cursor: not-allowed;
  }
  input.DateInput_input:read-only {
    opacity: 1;
    cursor: pointer;
  }
  .react-tel-input,
  .countryCode {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .SingleDatePickerInput__disabled {
    background-color: #fff;
  }
  .DateInput_input__disabled {
    opacity: 0.5 !important;
    display: initial;
    visibility: unset;
    cursor: not-allowed !important;
    font-style: unset;
  }
}
.sleep-modify-disable {
  opacity: 0.5;
}
.sleep-modify-fullname {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 30px;
}

.sleep-datepicker {
  .SingleDatePicker {
    width: 100%;
    .SingleDatePickerInput {
      width: 100%;
      border: 1px solid #e6e7e8;
      padding: 0 !important;
      border-radius: 3px;
    }
    .DateInput {
      width: 90%;
    }
    .DateInput_input {
      padding: 5px 10px;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }
    .DateInput_input__focused {
      border-bottom: 0;
    }
  }
  .SingleDatePickerInput_calendarIcon {
    display: none;
  }
  .SingleDatePicker_picker {
    top: 55px !important;
  }
  .DateInput_fang {
    top: 44px !important;
  }
}

.spacex-sleephome .CalendarDay__default {
  position: relative;
}

//Sleep Mobile
@media (max-width: 780px) {
  #BackgroundContainer .search.spacex-sleephome {
    margin-top: 20px !important;
    height: auto !important;
  }
  #BackgroundContainer .search.spacex-sleephome .departfrom {
    margin-top: 10px !important;
  }
  .spacex-sleep-bgHome .btnBlueSearch {
    margin-top: 30px !important;
  }
  .spacex-sleep-bgHome .btnBlueSearch button {
    height: auto !important;
  }
  .spacex-sleep-innerItem {
    padding: 10px !important;
    margin: 10px 0 10px 0 !important;
  }
  .spacex-sleep-wait h1 {
    font-size: 20px !important;
  }
  .sleep-dropdown {
    margin: 0 auto !important;
    .familySelect {
      width: 100%;
    }
  }
  .sleepModifyDateMobile {
    .roomName {
      font-weight: bold;
      margin-bottom: 20px;
      display: inline-block;
    }
    label {
      color: black !important;
      font-size: 14px !important;
      font-family: "Roboto", Myanmar3 !important;
      padding-bottom: 3px !important;
    }
    .modifyCheckin {
      margin-bottom: 20px;
    }
    .modifyCheckout {
      margin-bottom: 30px;
    }
    .SingleDatePicker {
      width: 100%;
    }
    .SingleDatePickerInput {
      width: 100%;
    }
    .DateInput {
      width: 85%;
    }
    .DateInput_input {
      font-size: 16px;
      padding: 7px 10px;
    }
  }
  .DayPicker_portal__horizontal {
    margin-left: -45% !important;
    width: 90% !important;
    min-width: unset !important;
  }
}
