.customerContainer {
  width: auto;
  max-width: 1000px;
  margin: auto auto 50px auto;
  padding: 20px 0px;
  overflow-anchor: none !important;
  .MuiInputBase-input {
    border: 1px solid #e6e7e8;
    padding: 7px 10px !important;
    border-radius: 3px;
    width: 100%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  // .MuiInputBase-formControl {
  //   padding-right: 15px;
  // }
}
.contactMobile {
  padding: 15px 15px 0px 15px;
  margin-bottom: 20px;
  overflow-anchor: none !important;
  .titleField {
    width: 30% !important;
    label.MuiInputLabel-animated {
      margin-top: -25px !important;
    }
    label.Mui-focused,
    label.MuiInputLabel-shrink {
      margin-top: -10px !important;
    }
  }

  .MuiInputBase-input {
    height: 40px;
    font-family: "Roboto", Myanmar3;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    overflow-anchor: none !important;
  }
  .MuiFormControl-root label {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    color: #a7a9ac !important;
    font-size: 14px !important;
    margin-top: -10px !important;
  }
  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px;
    overflow-anchor: none !important;
  }
  label + .MuiInput-formControl {
    margin-top: 7px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid #e6e7e8;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid #e6e7e8;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    height: 50px !important;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: 1px solid #707070 !important;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0px !important;
  }
  .MuiExpansionPanelSummary-content {
    margin: 0px !important;
  }
}

.title {
  color: #231f20;
  font-size: 24px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  line-height: 35px;
}
.fieldTitle {
  color: #231f20;
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  line-height: 35px;
}
.contactInfo {
  background-color: #fff;
  padding: 20px 20px;
  margin: 15px 0px 30px 0px !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-width: 110px;
}

.detailSummary {
  margin-left: 24px !important;
  max-width: 392px !important;
}
.fieldDescripiton {
  color: #a7a9ac;
  font-size: 12px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
}
.p-bottom-15 {
  padding-bottom: 15px;
}
.p-left-15 {
  padding-left: 15px;
}
.charter-info-cell-label {
  font-size: 12px;
  font-weight: bold;
  // border: 1px solid red;
  padding: 5px;
  margin: auto;
}
.charter-info-cell-input {
  font-size: 12px;
  padding: 5px;
  .SingleDatePicker,
  .SingleDatePickerInput,
  .DateInput {
    width: 100%;
  }
  .DateInput_input {
    width: 89%;
    padding: 5px 7px 5px 7px;
    font-size: 15px;
  }
}
@media (max-width: 780px) {
  .charter-region-mobile {
    .charterTitleMobile {
      font-size: 15px;
      margin: 0px 0 10px 0;
    }
    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput {
      width: 100%;
    }
    .SingleDatePicker,
    .SingleDatePickerInput {
      margin-bottom: 15px;
    }
    .DateInput_input {
      width: 89%;
      padding: 7px 10px;
      font-size: 15px;
      border-bottom: 0px;
    }
    .charterDobMobile {
      font-size: 14px;
    }
  }
}

.date {
  color: #000000;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}
.airportInfo {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  font-size: 14px;
}
.custFare {
  color: #00a651;
  font-size: 12px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
}
.airportName {
  color: #000000;
  font-size: 10px;
}
.MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 45px !important;
}
.MuiExpansionPanel-root {
  border-radius: 3px !important;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 10px 0px !important;
}
.btnBack {
  background-color: #e6eaed !important;
  width: 152px;
  margin-right: 20px !important;
  margin-top: 30px !important;
  span {
    color: #231f20 !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    text-transform: capitalize !important;
  }
}
.btnContinue {
  background-color: #00aeef !important;
  width: 152px;
  margin-top: 30px !important;
  span {
    color: #fff !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    text-transform: capitalize !important;
    pointer-events: none;
  }
}
.MuiSvgIcon-root {
  color: #707070 !important ;
}
.circle-line-time {
  width: 30px;
  background-color: #e6e7e8;
  border: 1px dashed #ccc;
  height: 1px;
  display: block;
  margin: 15px 0px 5px 0px;
}
.circleTime {
  border-radius: 20px;
  border: 1px solid #1ba0e2;
  background: #fff;
  width: 5px;
  height: 5px;
  display: block;
  margin-left: -5px;
  float: left;
}
.circleTime::after {
  content: "";
  display: block;
  width: 60px;
  height: 50%;
  margin-left: 100%;
  border-bottom: 1px dashed #ccc;
  float: left;
}
.fullCircleTime {
  border-radius: 20px;
  border: 1px solid #1ba0e2;
  background: #fff;
  width: 5px;
  height: 5px;
  display: block;
  margin-left: -5px;
  background-color: #00aeef;
  float: right;
}
.line {
  width: 65px;
  float: left;
  margin-top: 13px;
  margin-left: 95px;
  position: absolute;
}
.custDuration {
  margin-top: -15px;
  position: absolute;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin-left: 10px;
}
.custTransit {
  color: #7f91a8;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
  position: absolute;
  margin-top: 5px;
}
.airportInfo {
  height: 40px;
  width: 100%;
}

.MuiExpansionPanel-root:before {
  background-color: transparent !important;
}
.aiportDiv {
  height: 25px;
}
.total {
  color: #000000;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 12px;
}
.totalPrice {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  font-size: 14px;
  min-width: 120px;
  width: 150px;
}
.totalAmount {
  color: #00a651;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 15px;
}
.airline {
  color: #000000;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}
.airlineDiv {
  height: 55px;
}
.priceDetail {
  background-color: #e5f6f1;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: -30px !important;
  height: auto;
  bottom: 0px !important;
}
.feeName {
  color: #231f20;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 35px;
}
.feeAmount {
  color: #7f91a8;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
  float: right;
  line-height: 35px;
  width: 100%;
  text-align: right;
}
.custFlightTransit {
  color: #7f91a8;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}
.custTransitDiv {
  padding-left: 13px;
}
.custDetailTitle {
  padding: 10px 12px;
  border-bottom: 1px solid #e6e7e8;
  margin: auto;
  .fieldTitle {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
  }
}
.showMore {
  text-align: center;
  cursor: pointer;
}
.arrow {
  font-size: 15px !important;
  margin: -3px 2px;
  color: #000 !important;
}
.required {
  color: #f15a29;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}
.text-right {
  text-align: right;
}
.close {
  font-size: 17px !important;
  color: #000 !important;
  cursor: pointer;
}
.selectBox {
  width: 100%;
  border: 1px solid #e6e7e8 !important;
  height: 33px;
  border-radius: 3px;
  padding: 5px !important;
  background-color: #ffffff !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background-image: url("../images/down-arrow.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 97% center;
  font-size: 14px !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;

  &[name="dobDay"] {
    font-size: 12px !important;
    width: calc(100% - 1px) !important;
  }
  &[name="dobMonth"] {
    font-size: 12px !important;
  }
  &[name="dobYear"] {
    font-size: 12px !important;
    margin-left: 1px;
  }
}

.selectCountry {
  width: 95% !important;
  border: 1px solid #e6e7e8 !important;
  height: 33px;
  border-radius: 3px;
  padding: 5px !important;
}
.passengerBoxTitle {
  border: 1px solid #707070;
  padding: 15px !important;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  color: #231f20;
  font-size: 14px;
  width: 110%;
  span {
    width: 90%;
  }
  .MuiSvgIcon-root {
    color: #00aeef !important;
    margin-top: -8px;
    height: 30px;
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}
.mbCheckContact {
  color: #7f91a8;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px;
}
.flightDetailField {
  color: #231f20;
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  float: left;
}

.flightDepartCity {
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  float: left;
  margin-right: 5px;
  text-transform: capitalize;
}
.flightArrivalCity {
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  float: left;
  text-transform: capitalize;
}
.contactMobile .MuiExpansionPanel-root.Mui-expanded {
  margin-top: 0px !important;
}
.custDetailDiv {
  padding: 15px 15px 0px 15px;
}
.contactMobile {
  .priceDetail {
    margin-top: 0px !important;
    border-radius: 0px !important;
  }
  .MuiCollapse-wrapper {
    position: relative;
    flex-direction: column-reverse;
  }
  .custDetailDiv.mobileView .custFlightTransit {
    margin-top: 0px !important;
  }
}
.white-border {
  border: 0.7px solid #fff !important;
}
.personalInfoTitle {
  color: #ffffff;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  font-size: 16px;
  background-color: #00aeef;
  padding: 10px;
  text-align: center;
}
.custPriceSummary {
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: none !important;
}
#panel1a-header {
  order: 2;
  padding: 0px 15px !important;
}
.planeIcon {
  padding: 3px 10px;
  float: left;
}
.paymentBox {
  width: 100%;
  padding: 20px;
  border: 1px solid #e6e7e8;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
select option {
  width: 100px;
}
.paymentBox select:focus {
  width: auto;
}
select:focus option {
  min-width: 150px;
  width: 100px;
}
#select-paymentMethod {
  padding: 10px 0px 10px 10px !important;
  min-width: 100% !important;
  background-color: #fff !important;
  min-height: 30px;
  border-radius: 4px;
  line-height: 30px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 14px !important;
}

.Mui-focused,
.MuiFormLabel-filled {
  line-height: 20px !important;
}
#menu-paymentMethod {
  position: relative;
  z-index: 3000;
  // .MuiMenu-paper {
  //   min-width: 200px !important;
  //   max-width: 310px !important;
  //   margin-left: 305px !important;
  //   top: 350px;
  //   position: absolute;
  //   float: right;
  // }
  .MuiMenuItem-root {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 14px !important;
    min-height: 30px;
  }
}
#menu-paymentMethod option {
  width: 50px;
}
.passengerDetail {
  background-color: #f5f6f9 !important;
  box-shadow: none !important;

  .MuiExpansionPanelSummary-expandIcon {
    display: none !important;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0px !important;
  }
  .MuiExpansionPanelSummary-root {
    padding: 0px 15px !important;
  }
  .MuiExpansionPanelSummary-root.Mui-expanded {
    border-bottom: none !important;
  }
  .titleField {
    label {
      width: 100px;
      margin-top: -25px !important;
    }
  }
  label.MuiInputLabel-shrink {
    margin-top: -10px !important;
  }
}
.confirmationBox {
  display: block;
  position: absolute;
  top: 30%;
  padding: 30px;
  margin: auto;
  width: 400px;
  touch-action: none;
  border-radius: 8px !important;
  outline: none !important;
  left: calc(28% + 80px);
  text-align: center;

  button {
    width: auto;
    padding: 8px 30px;
    span {
      font-size: 16px;
    }
  }
  .description {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 16px;
    color: #231f20;
    margin-top: 30px;
    line-height: 25px;
  }
}
@media (min-width: 781px) and (max-width: 1223px) {
  .contactMobileTitle {
    padding-left: 15px;
  }
}
@media (max-width: 780px) {
  .popupSearch {
    .searchTitle {
      position: absolute;
      top: 0;
      left: -25px;
    }
    .MuiInputBase-input:focus {
      background: #fff !important;
    }
    .MuiInputBase-root {
      position: unset;
      margin-top: 10px !important;
      padding-left: 10px;
      padding-right: 15px;
    }
  }
  .slideContainter {
    position: fixed;
    overflow-x: hidden;
  }
  .countrySearch input {
    width: unset !important;
  }
  .countryPrefixSearch input {
    width: 100% !important;
  }
  .customerContainer {
    margin: auto !important;
  }
  .contactMobile {
    background-color: #fff;
    padding-top: 0px !important;
    .custPriceSummary {
      margin-bottom: 10px !important;
      .MuiIconButton-root {
        padding: 12px 0px !important;
      }
    }
    .MuiPaper-elevation1 {
      border: none !important;
    }
  }
  #menu-paymentMethod {
    z-index: 3000 !important;
    .MuiMenu-paper {
      min-width: 200px !important;
      max-width: 200px !important;
      margin-right: 35px !important;
      margin-top: 0px !important;
      float: right;
      position: relative;
      right: 0px !important;
    }
    .MuiPopover-paper {
      z-index: 4000;
    }
  }
  .contactMobileTitle {
    padding-left: 15px;
  }
}
//Edits
.popupSearch {
  .searchTitle {
    position: absolute;
    top: 0;
    left: -25px;
  }
  .MuiInputBase-input:focus {
    background: #fff !important;
  }
  .custDetailDiv {
    padding: 15px 15px 15px 12px !important;
  }
  .MuiInputBase-root {
    position: unset;
    margin-top: 10px !important;
    padding-left: 10px;
    padding-right: 15px;
  }
}
.countrySearch input {
  width: unset !important;
}
.countryPrefixSearch input {
  width: 100% !important;
}
.customerContainer {
  margin: auto !important;
}
.contactMobile {
  padding-bottom: 10px;
  background-color: #fff;
  padding-top: 0px !important;
  .custPriceSummary {
    margin-bottom: 10px !important;
    .MuiIconButton-root {
      padding: 12px 0px !important;
    }
  }
  .MuiPaper-elevation1 {
    border: none !important;
  }
}
.paymentMethodControl {
  width: 87%;
  padding: 0px 15px !important;
}
.contactMobileTitle {
  padding: 10px 15px 10px 15px;
  background-color: #fff;
}
.flightDetailField {
  font-size: 16px;
}
.custDetailDiv {
  padding: 15px 15px 15px 12px !important;
}
.continueBox {
  background-color: #fff;
  height: 60px;
  border: 1px solid #e6e7e8;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -10px !important;
  border-right: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  button {
    width: 100% !important;
    font-size: 16px;
    height: 40px;
  }
}
@media (max-width: 599px) {
  .confirmationBox {
    // left: 2% !important;
    width: 90% !important;
    padding: 20px 10px !important;
    top: 20% !important;
    button {
      width: auto;
      padding: 8px 15px;
      margin: 0px 5px !important;
    }
  }
  .MuiPaper-elevation1 {
    border: none !important;
  }
  #select-paymentMethod {
    font-size: 13px !important;
  }
  #menu-paymentMethod {
    z-index: 3000 !important;
    .MuiMenu-paper {
      min-width: 290px !important;
      max-width: 200px !important;
      margin-right: 30px !important;
      margin-top: 0px !important;
      float: right;
      position: relative;
      right: 0px !important;
    }
    .MuiPopover-paper {
      z-index: 4000;
    }
  }
  #select-paymentMethod {
    font-size: 13px !important;
  }
}
.confirmationBoxTitle {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  font-size: 20px;
}
.confirmationCircular {
  text-align: center;
  display: block;
}
.react-tel-input {
  .form-control {
    width: 100% !important;
    border: 1px solid #e6e7e8 !important;
  }
  .flag-dropdown {
    border: 1px solid #e6e7e8 !important;
  }
}
.countryCode {
  position: absolute;
  margin-top: -23px;
  margin-left: 28px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px !important;
  color: #231f20 !important;
}
.fixedBody {
  touch-action: none;
  overflow: hidden;
}
.customerInfo #gorgias-web-messenger-container {
  height: 150px !important;
}
#mount #messenger-button {
  margin-bottom: 50px !important;
}
.selectDiv {
  &:first-child {
    margin-top: 15px !important;
  }
  &:last-child {
    margin-top: 0;
  }
  .MuiFormControl-root label {
    width: 70% !important;
  }
}
.placeHolderText {
  color: #a5a6a7 !important;
}
.placeHolderText {
  color: #a5a6a7 !important;
}
select option {
  color: #000000;
}
select option[value="0"],
select option[value="00"],
select option[value="0000"] {
  color: #a5a6a7 !important;
  cursor: not-allowed !important;
}
.MuiFormHelperText-root {
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
}
.passengerDetail .titleField label {
  width: 125px !important;
}
//CONTACT PAGE INPUT
$form-border-color: #e4e4e4;
$input-border-color: #e6e7e8;
.passengerWebContainer .MuiSwitch-root {
  overflow: unset;
}
@media (max-width: 1024px) {
  .mobileNumberDiv label.Mui-error + div.MuiInput-root {
    margin-top: 5px !important;
  }
  label.MuiFormLabel-filled + .MuiInputBase-root {
    input#mobile_number {
      margin-top: -3px !important;
      height: 40px;
    }
  }
  .confirmationBox {
    left: 0;
    right: 0;
  }
  .countrySearch input {
    width: 100% !important;
  }
  .MuiSelect-select {
    width: 93% !important;
  }
  .continueBox {
    position: unset !important;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -10px !important;
    border-right: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .header + .MuiGrid-container {
    background: #f4f3f4;
    padding: 15px;
    & > .MuiGrid-item:nth-child(3) {
      padding-left: 2px !important;
      background: #f4f3f4;
      .title {
        background: #f4f3f4;
      }
    }
  }
  .contactMobileTitle {
    background: #f4f3f4;
    padding-left: 2px;
  }
  .personalInfoTitle {
    color: #000;
    background: #f4f3f4;
    text-align: left;
  }
  .contactMobile.summaryInfo {
    margin-bottom: 50px !important;
  }
  .contactMobile {
    //continue button issue
    margin-bottom: 0 !important;
    padding-top: 15px !important;
    border-radius: 5px;
    border: 1px solid #e4e4e4;

    .MuiInputLabel-animated {
      transform: none;
      transition: none;
    }
    .titleField {
      width: 100% !important;
      color: black !important;
      padding-bottom: 5px;
    }
    .titleField label.Mui-focused,
    .titleField label.MuiInputLabel-shrink {
      margin-top: -7px !important;
      padding-bottom: 0px !important;
    }
    .Mui-select-root {
      position: relative;
      border: 1px solid #707070;
      border-radius: 5px;
      margin-bottom: 20px;
      font-family: "Roboto-bold", Myanmar3 !important;
      color: #231f20;
      font-size: 14px;
    }
    .MuiFormControl-root {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
    .MuiFormControl-root label {
      color: black !important;
      font-family: "Roboto", Myanmar3 !important;
      padding-bottom: 3px !important;
    }
    .MuiFormLabel-asterisk {
      color: red;
    }
    .MuiInputBase-input {
      font-family: "Roboto", Myanmar3;
      padding-left: 5px !important;
    }
    .MuiInputBase-input:focus {
      background: none;
    }
    .MuiInput-underline:after,
    .MuiInput-underline:before {
      transition: none;
      transform: none;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after,
    .MuiInput-underline.Mui-error:after,
    .MuiInput-underline.Mui-error:before {
      border-bottom: none !important;
      transition: none;
    }
    .MuiFormHelperText-root.Mui-error {
      margin-top: -10px;
      margin-bottom: 25px !important;
    }
    label + .MuiInput-formControl {
      border-color: #e6e7e8 !important;
      border-width: 1px;
      border-style: solid !important;
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 20px;
      font-family: "Roboto-bold", Myanmar3 !important;
      color: #231f20;
      font-size: 14px;
    }
    .MuiInputLabel-formControl {
      transform: none !important;
      position: unset;
    }
    .MuiInputLabel-animated {
      transition: none !important;
    }

    .Mui-focused {
      .titleField label.Mui-focused,
      .titleField label.MuiInputLabel-shrink {
        margin-top: -13px !important;
      }
      .MuiInputBase-input:focus {
        transition: none;
        transform: none;
        line-height: inherit;
        padding: inherit;
      }
    }
    .MuiFormHelperText-root.Mui-error.Mui-focused {
      margin-top: -15px !important;
    }
    .Mui-error {
      .titleField label.Mui-focused,
      .titleField label.MuiInputLabel-shrink {
        margin-top: -13px !important;
      }
      .MuiInput-underline:after,
      .MuiInput-underline:before {
        transition: none;
        transform: none;
      }
      .MuiInput-underline:before,
      .MuiInput-underline:after,
      .MuiInput-underline.Mui-error:after,
      .MuiInput-underline.Mui-error:before {
        border-bottom: none !important;
        transition: none;
      }
    }
  }
  .dobSelectDiv {
    .MuiFormHelperText-root {
      margin-top: 10px !important;
      padding-bottom: 5px;
    }
  }
  .MuiPaper-elevation1 {
    border-radius: 5px !important;
    background-color: #fff !important;
    margin-bottom: 10px;
    .passengerDetail .MuiExpansionPanelSummary-root,
    #panel1a-header {
      border-radius: 5px;
      border: 1px solid #e4e4e4;
      max-height: 50px;
    }
  }
  .selectDiv.dobSelectDiv {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .Mui-expanded #panel1a-header {
    // border: 1px solid #e4e4e4;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .passengerDetail .MuiExpansionPanelSummary-root.Mui-expanded {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom: 1px solid #e6e7e8 !important;
  }
  .passengerBoxTitle {
    margin-bottom: 0 !important;
    border: none;
    padding: 0 !important;
    .MuiSvgIcon-root {
      right: 15px;
    }
    .passengerBoxTitleAsterisk {
      color: red !important;
      margin-left: -2px;
    }
  }
  .price-detail {
    border-left: 1px solid #e6e7e8 !important;
    border-right: 1px solid #e6e7e8 !important;
    border-radius: 0 !important;
  }
  .custPriceSummary #panel1a-header {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border: 1px solid $input-border-color;
    border-top: none !important;
  }
  .custPriceSummary.Mui-expanded #panel1a-header {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  .priceDetail {
    border-left: 1px solid $input-border-color;
    border-right: 1px solid $input-border-color;
  }
  //mobile prefix
  .mobileNumberDiv {
    & > .MuiFormControl-root {
      margin-top: -2px;
    }
    label {
      width: 142%;
      margin-left: -42%;
      margin-bottom: 2px;
    }
    label.Mui-focused {
      padding-bottom: 0;
      transform: none;
      transition: none;
    }
    input:focus {
      outline-offset: 0 !important;
    }
    label.MuiFormLabel-filled + div {
      margin-top: 3px !important;
    }
  }

  body {
    overflow-x: hidden;
  }
  .Mui-focused,
  .MuiFormLabel-filled {
    line-height: inherit !important;
    //font-size: 14px !important;
  }
  .MuiInput-underline.Mui-focused:before,
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    margin-top: 4px !important;
  }
  .travellerMobilePrefix .MuiFormHelperText-root.Mui-error.Mui-focused {
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .prefixList {
    margin-bottom: 0 !important;
  }
  .Mui-focused,
  .MuiFormLabel-filled {
    line-height: inherit !important;
    margin-top: 0 !important;
  }
  .MuiInput-underline.Mui-focused:after {
    transform: none !important;
    transition: none !important;
  }
  #mobile_number-helper-text {
    margin-left: -42%;
  }
  #mobile_label {
    color: black !important;
    font-family: "Roboto", Myanmar3 !important;
    font-size: 14px !important;
    width: 100%;
  }
  .mobileContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .searchBox {
      margin-top: 0 !important;
    }
  }
  .mobileNoDiv {
    width: 100% !important;
    border-radius: 5px;
    align-self: flex-start;
    margin-top: -5px;
    margin-bottom: 30px;
    overflow-anchor: none !important;
    span {
      font-size: 14px;
    }
    .react-tel-input .form-control {
      height: 40px;
    }
  }
  .contactMobile .prefixBox {
    padding: 0px 7px;
    margin-top: -7px;
    span {
      text-align: left;
    }
  }
  .searchBox {
    .MuiInputBase-input {
      width: 100% !important;
    }
  }
  .topDiv .clickAwayDiv {
    float: none;
    .searchBox {
      border: 1px solid $input-border-color;
      border-radius: 5px;
      margin-top: 0;
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 10px;
      width: 100%;
      height: 40px;
    }
    .MuiGrid-item {
      max-width: unset !important;
      margin-bottom: 0 !important;
    }
    .Mui-error {
      margin-top: 1px;
    }
  }
}
@media (min-width: 781px) and (max-width: 1024px) {
  .contactMobile .MuiFormControl-root {
    width: 100%;
  }
  .mobile-from-to {
    display: block !important;
  }
  .slideContainter {
    position: fixed;
    overflow-x: hidden;
    top: 0px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 999;
  }
  .popupSearch {
    height: 95px;
    background-color: #00aeef;
    position: sticky;
    top: 0;
    .searchTitle {
      color: #ffffff;
      font-family: "Roboto", Myanmar3;
      font-weight: 500;
      line-height: 40px;
      padding-top: 2px;
      margin-left: 40%;
      font-size: 16px;
    }
    .MuiTextField-root {
      width: 91%;
      margin-top: -5px !important;
    }
    .MuiInputBase-input {
      text-align: left !important;
      padding: 10px 15px !important;
      border-radius: 5px;
    }
    .MuiInputBase-input::placeholder {
      font-family: "Roboto", Myanmar3;
      font-weight: 400;
      font-size: 14px;
    }
    .MuiInputBase-root {
      left: 13px !important;
      margin-top: 10px !important;
    }
    .MuiButton-text {
      margin-left: -6px;
    }
    input {
      float: left;
      background-color: #fff;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: -30px;
    }
  }
  .phoneprefixGrid {
    max-width: 100% !important;
  }
  .prefixList {
    padding: 10px 5px;
  }
  .mobileContainer .popupSearch .MuiTextField-root {
    width: 100%;
    margin-top: 0px !important;
  }
  .countryPrefixSearch input {
    width: 100% !important;
  }
  #PreFixTextField {
    height: 40px;
  }
}
@media (min-width: 1025px) {
  //fix customer info page flightdetail box
  .customerContainer .contactMobile {
    margin-top: 15px !important;
  }
  .countrySearch input {
    width: 82% !important;
  }
  .countryPrefixSearch input {
    width: 82% !important;
  }
  .prefixBox {
    border-bottom: 1px solid black !important;
  }
  .contactMobile {
    margin-top: 15px !important;
    padding-top: 15px !important;
    padding: 15px !important;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    height: 100%;
  }
  .contactInfo .phoneBox .MuiInputBase-input {
    margin-left: 0px !important;
  }
  .contactInfo {
    & > .MuiGrid-item:nth-child(2),
    & > .MuiGrid-item:nth-child(3),
    & > .MuiGrid-item:nth-child(6) {
      // padding-left: 15px;
    }
  }
  .contactInfo .selectBox {
    width: 100%;
    max-width: 90px;
  }
}
.countryPrefixSearch {
  border: none !important;
}
.countryPrefixSearch input {
  height: 20px !important;
  margin-top: -5px !important;
}
.prefixList {
  color: #000 !important;
  border-bottom: 1px solid #ccc !important;
}
.mainPrefixContainer {
  width: 100%;
  color: #00aeef;
}

.leftPrefixContainer {
  width: 80%;
  float: left;
}
.rightPrefixContainer {
  width: 19%;
  float: left;
  text-align: right;
  margin-right: 1% !important;
}
.mobileContainer {
  width: 100%;
  overflow-anchor: none !important;
}
.mobilePrefixDiv {
  width: 30%;
  float: left;
  overflow-anchor: none !important;
}
.mobileNumberDiv {
  width: 70%;
  overflow-anchor: none !important;
  float: left;
  overflow-anchor: none !important;
  margin-top: -7px;
}
.prefixBox {
  // border-bottom: 1px solid #000 !important;
  height: 26px !important;
  overflow-anchor: none !important;
}
.fullPrefixContainer {
  width: 100%;
}
.countrySearch input {
  height: 20px !important;
  margin-top: -5px !important;
}
.topLable {
  margin-top: -25px !important;
}
.dobSelectDiv {
  padding-bottom: 15px !important;
}
.MuiGrid-container {
  overflow-anchor: none !important;
}
#PreFixTextField {
  color: transparent;
  position: absolute;
  z-index: 1000;
  height: 0;
}
.MuiFormControl-root label.optionalLabel {
  position: relative;
  font-size: 13px !important;
  left: 105px;
  top: 7px;
  color: #999999 !important;
}
.customerContainer .optionalLabel {
  color: #999999 !important;
  font-size: 13px !important;
  margin-left: 5px;
}
.MuiSwitch-root.Component-root-201,
.MuiSwitch-root.Component-root-255 {
  float: right;
  overflow: visible;
}
.contactMobile .MuiFormControl-root label.mobile-spacex-label {
  color: #fff !important;
}

#weight-helper-text {
  margin-top: -10px !important;
  margin-bottom: 25px !important;
}
.btnContinue.flyInfoBtn {
  margin-top: 0px !important;
}
.btnContinue.flyInfoBtn .MuiCircularProgress-root,
.flyInfoBtnMobile .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  margin-left: 5px;
}

#boca-work::placeholder {
  font-size: 12px;
}

#boca-work:focus::placeholder {
  color: transparent;
}
/* for mobile (report-purpose) field */
.report-purpose > div.MuiInput-formControl {
  margin-bottom: 3px;
}

.report-purpose > p.MuiFormHelperText-root:not(.Mui-error) {
  margin-top: 0;
  margin-bottom: 20px;
}

.report-purpose > p#standard-name-helper-text.Mui-error {
  margin-top: 0px;
}
.report-purpose > p.Mui-error.Mui-focused {
  padding-top: 13px;
}

.report-purpose
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input::placeholder {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-weight: 400;
}

.report-purpose .MuiInputBase-input::placeholder {
  transition: none;
}
.report-purpose .MuiInputBase-input:focus::placeholder {
  color: transparent;
}
.auto-filled .MuiInputBase-root {
  pointer-events: none;
}
.auto-filled .MuiInputBase-input {
  border: 0px;
  padding-left: 0px !important;
}
@media (min-width: 780px) {
  .auto-filled .MuiInputBase-input {
    color: #777;
  }
}

.contactMobile .auto-filled label + .MuiInput-formControl {
  border: 0px;
}
