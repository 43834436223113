.serachDetail {
  width: 889px !important;
  margin: auto;
}
.resultCard {
  padding-bottom: 30px !important;
}
.flydetailBox {
  min-height: 100px;
  height: auto;
  border: 1px solid #e4e4e4;
  width: 889px;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #fff !important;
}
.flydetailBox:hover {
  border: 1px solid #00aeef !important;
}
.flydetailBox .warningText {
  color: red;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  font-family: "Roboto";
  margin-top: 5px;
}
.flyinfoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flyinfoSection span {
  font-family: "Roboto" !important;
  font-size: 18px;
  font-weight: 700;
}
.flightBookBtn {
  .MuiButton-root.Mui-disabled {
    background-color: rgba(19, 1, 1, 0.25) !important;
  }
}
@media (max-width: 780px) {
  .flydetailBox.mobile {
    padding: 0 !important;
  }
  .flydetailBox .warningText {
    margin-top: 0;
    margin-bottom: 5px;
  }
  .flyinfoSection span {
    padding: 10px 15px 0 15px;
    font-size: 18px;
  }
  .flyinfoSection .text-date {
    margin-top: 0px;
  }
  .btnChoose > span {
    margin: 0px;
  }
  .flyarrivalInfo {
    h5 {
      font-size: 22px !important;
    }
    .flightDestination .firstPhrase {
      font-size: 22px !important;
    }
    .vectorSection {
      right: 5px !important;
    }
  }
  .flyarrivalInfo.mobile {
    padding: 10px 15px;
  }
  .dividedMobile {
    border-bottom: 1px solid #d9d9d9;
    display: inline-block;
    width: 100%;
    height: 1px;
  }
  .flyAvaSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    span {
      font-family: "Inter" !important;
      font-size: 14px;
      font-weight: 400;
      color: #000;
      margin-top: 8px;
    }
  }
  .iconSection {
    flex-direction: row !important;
    align-items: center !important;
    span {
      margin-left: 6px;
    }
    div {
      justify-content: flex-start !important;
    }
  }
  .flightBookBtn {
    button {
      width: 92% !important;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.flyarrivalInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span.infoType {
    font-family: "Roboto" !important;
    color: initial;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
  }
  h5 {
    font-family: "DDinRegular" !important;
    letter-spacing: 0.4px;
    font-size: 24px;
    color: #000;
    margin: 0;
  }
  .flightDestination {
    color: #7f91a8;
    display: flex;
    flex-direction: row;
    align-items: center;
    .firstPhrase {
      font-family: "DDinBold" !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      margin-right: 10px;
    }
    .secondPhrase {
      font-family: "Roboto" !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .vectorSection {
    position: absolute;
    right: 22px;
    top: 50%;
  }
}
.iconSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  img {
    width: 28px;
    height: 28px;
    margin-right: 5px;
  }
}
.flightBookBtn {
  button {
    width: 100%;
    padding: 12px !important;
    span {
      font-family: "InterBold" !important;
      font-size: 16px;
      font-weight: 700;
    }
    .fl-result-btn-disable {
      margin-bottom: 2px !important;
      background-color: #7f91a8 !important;
    }
  }
  .availability {
    font-family: "Roboto" !important;
    font-size: 14px;
    margin-top: 5px;
    color: #000;
    text-align: center;
  }
}
.hovercraftBtn {
  button {
    margin-top: 20px !important;
  }
}

.availability {
  font-size: 12px;
  color: #7f91a8;
}
.familyFriendAvailable {
  font-size: 11px;
  color: #273142;
}
.flyresult {
  background-color: #273142;
  position: fixed;
  margin: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.flyresult .innerflyresult {
  padding-bottom: 20px !important;
}
.spacex-pointerEvent {
  pointer-events: none;
}
.flysendTitle {
  width: 889px !important;
  margin: auto;
  color: #abaeaf;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
}
.flysendTitle a {
  color: #abaeaf;
}
.divFare .fly-send-email {
  line-height: 25px;
  font-size: 14px;
}

@media (max-width: 959px) {
  .serachDetail.flysearchDetail {
    width: auto !important;
    padding: 10px;
    background-color: #273142;
  }
}

@media (min-width: 781px) {
  .divDetail {
    margin-top: 15px;
  }
}
@media (max-width: 780px) {
  .divDetail {
    margin-bottom: 15px;
  }
}
.airlnelbl {
  font-size: 14px !important;
  color: #7f91a8 !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400 !important;
  line-height: 20px;
}
.airlnelblMobile {
  font-size: 12px !important;
  color: #7f91a8 !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400 !important;
  line-height: 20px;
}
.btnChoose {
  background-color: #00aeef !important;
  color: #fff !important;
  padding: 8px 24px !important;
  margin-top: 10px !important;
  text-transform: capitalize !important;
  span {
    line-height: 17px;
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    pointer-events: none;
  }
}
a {
  text-decoration: none;
  color: #fff;
}
.checkReturn {
  padding: 0px !important;
  .MuiSvgIcon-root {
    color: #00aeef !important;
    width: 20px !important;
    height: 20px !important;
  }
}
.circle {
  border-radius: 20px;
  border: 1px solid #1ba0e2;
  background: #fff;
  width: 8px;
  height: 8px;
  display: block;
  margin-left: -5px;
  float: left;
}
div.airlineInfo {
  display: flex;
  align-items: center;
}
.airlineName {
  font-size: 14px;
  font-family: "Roboto", Myanmar3;
  font-weight: 700;
  color: #7f91a8;
}
.airlineClass {
  color: #7f91a8;
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
}
.fadein,
.fadeout {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 300ms ease-out;
  -moz-transition: max-height 300ms ease-out;
  -o-transition: max-height 300ms ease-out;
  transition: max-height 300ms ease-out;
}
.fadein {
  max-height: 200px;
}
.editForm {
  width: 100%;
  input {
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20;
  }
}
.DateRangePickerInput__withBorder {
  border: none !important;
  width: 100%;
}
.DateInput_input__disabled {
  background-color: #fff !important;
  display: none;
  visibility: hidden;
}
.divArrival {
  margin-bottom: 30px !important;
}
.divFare {
  text-align: center !important;

  span {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
  }
}
.citizen {
  padding: 5px 10px 20px 20px !important;
}
// @media (max-width: 800px) {
@media (max-width: 959px) {
  .header {
    // height: 40px !important;
    background-color: #fff !important;
    .homeContainer {
      // height: 40px !important;
      padding: 0px !important;
    }
  }
  #root > .header > .homeContainer,
  #root > .bgWhite > .header > .homeContainer,
  #root > .MuiGrid-container > .header > .homeContainer {
    & > .MuiGrid-item:first-child {
      display: none;
      visibility: hidden;
    }
  }
  .summaryFlight,
  .serachDetail {
    width: auto !important;
    padding: 10px;
    background-color: #f4f3f4;
  }
  .citizen {
    padding: 5px 20px !important;
  }
  .MuiInputAdornment-root {
    float: right;
    margin-top: 3px;
    .MuiSvgIcon-root {
      color: #2699fb !important;
      float: right;
    }
  }

  .fadein {
    max-height: 600px !important;
  }
  .divDepart,
  .divArrival {
    margin-bottom: 7px !important;
    .airlnelbl {
      font-size: 15px !important;
      position: relative !important;
      margin-top: 0px !important;
    }
  }
  .DateInput_input__disabled {
    font-style: normal !important;
  }
  .divArrival {
    margin-bottom: 10px !important;
  }
  .circle-line {
    height: 62px !important ;
  }
  .fare {
    color: #00a651 !important;
    font-size: 18px !important;
  }
  .btnChoose {
    min-height: 40px;
    margin-top: 10px !important;
    span {
      pointer-events: none;
    }
  }
  .divFare {
    text-align: right !important;
  }
  .serachDetail {
    .details {
      width: 25%;
      float: left;
      margin-top: -30px;
    }
  }
  .chooseReturnDate {
    height: 60px !important;
    .MuiSwitch-root {
      overflow: visible !important;
      margin: 0px !important;
    }
  }
  .m-top-10 {
    margin-top: 10px !important;
  }
  .fieldLbl {
    line-height: 60px;
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: #a5a7a6 !important;
  }
  .departfrom.return,
  .mdatepickerReturn {
    border-bottom: none !important;
  }
  div.airlineInfo {
    margin-top: -27px;
  }
  div.airlineInfo .airlnelbl {
    line-height: 20px;
    margin-left: 7px;
    margin-top: -13px;
  }
}
.center {
  text-align: center !important;
}
.full-width {
  width: 100%;
}
.noResult {
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #2699fb;
  padding: 10px 20px;
  font-size: 18px;
}
.searchErrMessage {
  input,
  .selectBox {
    border: 1px solid #ff0000 !important;
  }
  .MuiInputBase-input {
    border: 1px solid #ff0000 !important;
  }
  .errMessage {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    color: #ff0000;
    font-size: 12px;
  }
}
.react-numeric-input b:nth-child(2) i {
  background-color: #23af2e !important;
}
.react-numeric-input b:last-child i {
  background-color: #ff5757 !important;
}
.MuiFormControlLabel-root {
  margin-right: 0 !important;
}
@media (max-width: 600px) {
  .arrivalInfo,
  .duration {
    display: none;
  }
  .divFare.mobile {
    text-align: left !important;
  }
}
@media (min-width: 760px) and (max-width: 1000px) {
  .serachDetail {
    .m-top-10 {
      margin-top: 0px !important;
    }
  }
}
#root > .header > .homeContainer,
#root > .bgWhite > .header > .homeContainer,
#root > .MuiGrid-container > .header > .homeContainer {
  justify-content: center;
}
@media (max-width: 780px) {
  .homeContainer .flightSearch .DateInput_input {
    width: 100% !important;
  }
}
@media (max-width: 375px) {
  .header {
    height: 50px !important;
  }
  #root > .header >,
  #root > .bgWhite > .header > {
    & > .MuiGrid-item:first-child {
      display: none;
    }
  }
  div.airlineInfo {
    margin-top: -18px !important;
  }
  .infoSection .airlnelbl {
    margin-top: 0 !important;
  }
  .infoSection .airlnelbl {
    line-height: 20px;
    font-size: 15px !important;
    max-width: 150px;
    margin-left: 10px;
  }
  .fare {
    font-size: 17px !important;
  }
}
@media (max-width: 370px) {
  .infoSection .airlnelbl {
    margin-left: -10px;
  }
  div.airlineInfo {
    margin-top: 0px !important;
  }
}
@media (min-width: 959px) and (max-width: 1024px) {
  .SingleDatePickerInput__withBorder {
    border: none !important;
  }
}
@media (max-width: 1024px) {
  .header {
    height: 50px !important;
  }
  #root > .header > .homeContainer,
  #root > .bgWhite > .header > .homeContainer,
  #root > .MuiGrid-container > .header > .homeContainer {
    .MuiGrid-item {
      display: flex;

      align-items: center;
      height: 50px;
    }
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .SingleDatePickerInput__withBorder {
    background: transparent;
    border: none;
    /* border: 1px solid #dbdbdb; */
  }
}
//tablet size and above
@media (min-width: 960px) {
  .infoSection .airlnelbl {
    margin-left: 7px;
  }
}
.fare {
  color: #00a651 !important;
}

span.connectingFlight {
  color: #2699fb;
}
